<template>
  <div>
    <v-menu
      content-class="user-profile-menu-content"
      left
      min-width="230"
      nudge-bottom="14"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            :color="!picture ? 'white' : ''"
            size="40"
          >
            <b
              v-if="!picture"
              class="accent--text font-weight-semibold"
            >
              {{ initialName }}
            </b>

            <v-img
              v-else
              :src="picture"
            />
          </v-avatar>

          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="white--text font-weight-semibold mb-n1">
              {{ username }}
            </span>
          </div>
        </div>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            class="ms-4"
            color="success"
            dot
            offset-x="12"
            offset-y="12"
            overlap
          >
            <v-avatar
              color="primary"
              size="40"
            >
              <b class="white--text font-weight-semibold">
                {{ initialName }}
              </b>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{ username }}
            </span>
          </div>
        </div>

        <v-divider class="my-2" />

        <v-list-item
          to="/account/wallet-extract"
          link
        >
          <v-list-item-icon class="me-2">
            <v-icon
              class="primary--text"
              size="22"
            >
              {{ icons.mdiWallet }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Saldo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item @click="openFormChangePassword">
          <v-list-item-icon class="me-2">
            <v-icon
              class="primary--text"
              size="22"
            >
              {{ icons.mdiKey }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />

        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon class="me-2">
            <v-icon
              class="primary--text"
              size="22"
            >
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Sair
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <validation-observer
      ref="changePassword"
      autocomplete="off"
      tag="form"
    >
      <dialog-form
        :status="formDialogStatus"
        :width="400"
        title="Alterar senha"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
        @action="submit"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Senha atual"
          rules="required"
          vid="currentPassword"
        >
          <v-text-field
            v-model="form.currentPassword"
            :error-messages="errors"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            :type="isPasswordVisible ? 'text' : 'password'"
            label="Senha atual"
            nome=""
            outlined
            dense
            @click:append="isPasswordVisible = !isPasswordVisible"
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Nova Senha"
          vid="newPassword"
        >
          <v-text-field
            v-model="form.newPassword"
            :error-messages="errors"
            type="password"
            label="Nova Senha"
            nome=""
            outlined
            dense
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Confirmação da nova senha"
          rules="required|confirmed:newPassword"
          vid="newPasswordConfirmation"
        >
          <v-text-field
            v-model="form.newPasswordConfirmation"
            :error-messages="errors"
            type="password"
            label="Confirmação de Senha"
            nome=""
            outlined
            dense
          />
        </validation-provider>
      </dialog-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiLogoutVariant,
  mdiWallet,
  mdiKey,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import { extend } from 'vee-validate'
import { required, confirmed, sameAs } from 'vee-validate/dist/rules'
import { changePassword } from '@/store/auth/actions'

extend('confirmed', {
  ...confirmed,
  message: 'As senhas devem ser iguais.',
})
export default {
  setup() {
    const name = localStorage.getItem('fullname')
    const username = name.split(' ')[0]
    const initialName = username.substring(0, 2).toUpperCase()
    const form = ref({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    })
    const isPasswordVisible = ref(false)
    const formDialogStatus = ref(false)
    const removeDialogStatus = ref(false)
    const picture = ref(null)

    return {
      username,
      initialName,
      formDialogStatus,
      removeDialogStatus,
      form,
      isPasswordVisible,
      picture,
      icons: {
        mdiAccount,
        mdiLogoutVariant,
        mdiWallet,
        mdiKey,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },

  created() {
    this.picture = localStorage.getItem('picture')
  },

  methods: {
    logout() {
      localStorage.removeItem('fullname')
      localStorage.removeItem('token')
      localStorage.removeItem('tenant')
      localStorage.removeItem('isAffiliate')
      localStorage.removeItem('picture')

      this.$router.push('/signin')
    },
    async submit() {
      const isValid = await this.$refs.changePassword.validate()
      if (isValid) {
        const hashedPasswords = {
          currentPassword: this.$sha256(this.form.currentPassword),
          newPassword: this.$sha256(this.form.newPassword),
          newPasswordConfirmation: this.$sha256(this.form.newPasswordConfirmation),
        }
        const res = await changePassword(hashedPasswords)
        if (res) {
          this.formDialogStatus = false
          this.$refs.changePassword.reset()
          this.form.currentPassword = ''
          this.form.newPassword = ''
          this.form.newPasswordConfirmation = ''
        }
      }
    },
    async openFormChangePassword() {
      this.formDialogStatus = true
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
