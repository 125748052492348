export default {
  loading: false,
  notify: {
    visible: false,
    color: null,
    message: null,
    time: null,
  },
  tutorials: [
    {
      key: 'TSP0',
      title: 'Inserir Saldo',
      video: 'https://www.youtube.com/embed/Pibzmc7fcKs?si=qqNc7MWTssOKCAIt',
    },
    {
      key: 'TSP1',
      title: 'Criar SMS Template',
      video: 'https://www.youtube.com/embed/gUVknkVWijw?si=R_m4N4AhLks-twZo',
    },
    {
      key: 'TSP2',
      title: 'Criar Campanha de SMS',
      video: 'https://www.youtube.com/embed/ECzY-FfEl4I?si=yMRQZ9W2vkaUUI7N',
    },
    {
      key: 'TSP3',
      title: 'Criar Campanha de Higienização',
      video: 'https://www.youtube.com/embed/Cqepg-P7n84?si=Yg7QbqkJis4tlFai',
    },
    {
      key: 'TSP4',
      title: 'Criar Campanha de Voz Simples',
      genericTitle: 'Criar Campanha de Voz',
      video: 'https://www.youtube.com/embed/PijOr048Zkc?si=cq1NnBqB2LNVpJU3',
    },
    {
      key: 'TSP5',
      title: 'Criar Campanha de Voz Interativa',
      genericTitle: 'Criar Campanha de Voz',
      video: 'https://www.youtube.com/embed/gQKhxEWXyTs?si=w-pCaHhrGkyXfHwA',
    },
  ],
}
