import Vue from 'vue'
import axios from '@/plugins/axios'

export const signin = async ({ commit }, body) => {
  Vue.prototype.$loading(true)

  try {
    localStorage.setItem('email', body.email)

    const { data } = await axios.post('auth/login', body)
    const { token, user } = data

    if (token) {
      localStorage.setItem('token', token)
      localStorage.setItem('fullname', user.name)
      localStorage.setItem('role', btoa(user.role))
      localStorage.setItem('tenant', user.tenant)
      localStorage.setItem('isAffiliate', user.isAffiliate)
      localStorage.setItem('id', user.id)

      commit('setRole', user.role)

      axios.defaults.headers.authorization = `Bearer ${token}`
    }

    return data
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const signup = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('user', body)

    return status
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const tokenVerify = async (_, hash) => {
  try {
    const { data, status } = await axios.get(`token/verify/${hash}`)

    return { data, status }
  } catch (err) {
    return err
  }
}

export const recoveryPassword = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('user/reset-password', body)

    return status
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const updatePassword = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('user/update-password', body)

    return status
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const changePassword = async body => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('user/change-password', body)

    return status
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const gAuthSignin = async ({ commit }, body) => {
  Vue.prototype.$loading(true)

  try {
    const { data } = await axios.post('gauth', body)
    const { token, user, picture } = data

    if (token) {
      localStorage.setItem('token', token)
      localStorage.setItem('fullname', user.name)
      localStorage.setItem('role', btoa(user.role))
      localStorage.setItem('tenant', user.tenant)
      localStorage.setItem('isAffiliate', user.isAffiliate)
      localStorage.setItem('id', user.id)
      localStorage.setItem('picture', picture)

      commit('setRole', user.role)

      axios.defaults.headers.authorization = `Bearer ${token}`
    }

    return data
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
